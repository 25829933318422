<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_105_932" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect x="24" width="24" height="24" transform="rotate(90 24 0)" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_105_932)">
            <path
                d="M15 13L17 13L17 16L21 16L21 18L17 18L17 21L15 21L15 13ZM3 16L13 16L13 18L3 18L3 16ZM3 6L7 6L7 3L9 3L9 11L7 11L7 8L3 8L3 6ZM11 6L21 6L21 8L11 8L11 6Z"
                fill="#474747"
            />
        </g>
    </svg>
</template>

<script setup lang="ts"></script>

<style></style>
